window.angular.module('MyHippoProducer.Components').directive('formSection', function () {
    return {
        restrict: 'A',
        scope: { pageKey: '@', sectionKey: '@', title: '@' },
        templateUrl: 'components/form/section/section.html',
        controller: function ($scope, WorkflowAdapter, $timeout, $anchorScroll, $location) {
            $scope.fields = WorkflowAdapter.getAllFieldsForSection($scope.pageKey, $scope.sectionKey);
            $scope.displayField = (relatedSection, collapsedField) => {
                const sameMailingAddress = WorkflowAdapter.getField('optional_coverages.held_in_trust_details.same_mailing_address');
                if (sameMailingAddress.display) {
                    return !collapsedField.visible && relatedSection.properties.type === 'collapsed';
                }
                return relatedSection.properties.type === 'collapsed' && relatedSection.isVisible;
            };
            //scroll to subsection when has anchor in url
            if ($scope.title) {
                $scope.anchor = $scope.title.split(' ').join('-').toLocaleLowerCase();
            }
            if ($location.hash()) {
                $timeout(function () {
                    $anchorScroll($scope.anchor);
                }, 400);
            }
        }
    };
});
